import React from "react"
import {IonItem, IonNote} from "@ionic/react";
import styled from "styled-components";


const Small = styled(IonNote)`
  padding: 0;
  margin: 0;
  color: red;
`


type NoteElementTypes = {
    note: string

}

const NoteElement = ({note}: NoteElementTypes) => {
    return (
        <IonItem lines={'full'}>
            <IonNote>
                <div style={{fontSize: "0.8rem"}}>{note}</div>

            </IonNote>
        </IonItem>
    )
}

export default NoteElement