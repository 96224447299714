/*jshint esversion: 6 */
import React, {useContext, useState, useEffect} from 'react';
import {auth, google} from "../firebase";

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext)
}

export const AuthProvider = ({children}) => {
    const [currentUser, setCurrentUser] = useState();
    const [token, setToken] = useState()


    const [loading, setLoading] = useState(true);

    const signup = (email, password) => {
        return auth.createUserWithEmailAndPassword(email, password);

    }

    const login = (email, password) => {
        return auth.signInWithEmailAndPassword(email, password)
    }

    const logout = () => {
        return auth.signOut()
    }

    const loginWithGoogle = () => {
        return auth.signInWithPopup(google)
        //return auth.signInWithRedirect(google)
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            setCurrentUser(user)
            
            if (user) {
                user.getIdToken(true).then((r) => {

                    setToken(r);
                    setLoading(false);

                })
            } else {
                setLoading(false);
            }


        })

        return unsubscribe;
    }, [])


    const value = {currentUser, token, signup, login, logout, loginWithGoogle}

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};

