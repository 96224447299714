import React, {useState} from "react"
import SmallSpace from "../../../components/helpers/SmallSpace";
import {IonButton, IonInput, IonItem, IonLabel, IonText} from "@ionic/react";


const padding: Number = 40;

type ExerciseEditProps = {
    data: any
    index: number
    editfunction: any
    set: any
}

const ExerciseEdit = ({data, set, index, editfunction}: ExerciseEditProps) => {
    const [isTimed, setIsTimed] = useState(data[index].data[0].isTimed)
    const [exerciseName, setExerciseName] = useState(data[index].data[0].name)
    const [amrap, setAmrap] = useState('AMRAP')
    const [restColor, setRestColor] = useState('medium')


    const handleReps = () => {
        const min = parseInt(data[index].repsMin)
        const max = parseInt(data[index].repsMax)
        if (data[index].repsMax !== '' && data[index].repsMin !== '') {
            if (min > max) {
                const newArr = [...data]
                newArr[index].repsMin = data[index].repsMax
                set(newArr)

            }
        }
        if (data[index].repsMin === '0') {
            const newArr = [...data]
            newArr[index].repsMin = ''
            set(newArr)
        }
        if (data[index].repsMax === '0') {
            const newArr = [...data]
            newArr[index].repsMax = ''
            set(newArr)
        }


    }

    const handleIntensity = () => {
        const n = parseInt(data[index].intensity)
        if (n > 100) {
            const newArr = [...data]
            newArr[index].intensity = ''
            set(newArr)

        }

    }

    const handleRPE = () => {
        const n = parseInt(data[index].rpe)
        if (n > 10) {
            const newArr = [...data]
            newArr[index].rpe = '10'
            set(newArr)

        }

    }


    return (
        <>
            <SmallSpace space={10}/>

            {/*Exercise*/}
            <IonItem color={"light"} lines={"full"}>
                <IonLabel>
                    <strong>{exerciseName}</strong>
                </IonLabel>
                <IonText>
                    <IonButton fill={"outline"} onClick={() => editfunction(index)}>Change</IonButton>
                </IonText>
            </IonItem>


            {/*Reps*/}
            <IonItem color={"light"}>
                <IonLabel
                    position="fixed"
                    slot={"start"}>
                    {isTimed ? (<>Secs Min:</>) : (<>Reps Min:</>)}

                </IonLabel>
                <IonInput
                    type={'number'}
                    inputmode={'numeric'}
                    placeholder={"Optional"}
                    className={"ion-text-right"}
                    value={data[index].repsMin}
                    onIonBlur={handleReps}

                    onIonChange={e => {
                        const newArr = [...data]
                        newArr[index].repsMin = e.detail.value!
                        set(newArr)

                        if (e.detail.value !== '' && e.detail.value !== '0' && e.detail.value !== null) {

                            if (isTimed) {
                                setAmrap('AMSAP')
                            } else {
                                setAmrap('AMRAP')
                            }

                        } else {
                            setAmrap('Optional')
                        }
                    }}
                >
                </IonInput>
                <IonLabel
                    position="fixed"
                    placeholder={"Optional"}
                    style={{paddingLeft: padding}}
                >
                    Max:
                </IonLabel>
                <IonInput
                    type={'number'}
                    value={data[index].repsMax}
                    inputmode={'numeric'}
                    placeholder={amrap}
                    className={"ion-text-right"}
                    onIonChange={e => {
                        const newArr = [...data]
                        newArr[index].repsMax = e.detail.value!
                        set(newArr)
                    }}
                    onIonBlur={handleReps}

                >

                </IonInput>
            </IonItem>

            {/*Intensity*/}
            <SmallSpace space={0}/>
            <IonItem color={"light"}>
                <IonLabel>
                    Intensity
                </IonLabel>
                <IonInput
                    value={data[index].intensity}
                    onIonChange={e => {
                        const newArr = [...data]
                        newArr[index].intensity = e.detail.value!
                        set(newArr)
                    }}
                    className={"ion-text-right"}
                    placeholder={"Optional"}
                    onIonBlur={handleIntensity}
                    type={'number'}
                    inputmode={'numeric'}

                >
                </IonInput>
                <IonLabel slot={"end"}>
                    %
                </IonLabel>
            </IonItem>

            {/*RPE*/}
            <IonItem color={"light"}>
                <IonLabel>
                    RPE
                </IonLabel>
                <IonInput

                    value={data[index].rpe}
                    onIonChange={e => {
                        const newArr = [...data]
                        newArr[index].rpe = e.detail.value!
                        set(newArr)
                    }}
                    onIonBlur={handleRPE}
                    className={"ion-text-right"}
                    placeholder={"Optional"}
                    type={'number'}
                    inputmode={'numeric'}

                >
                </IonInput>

            </IonItem>


            {/*Rest duration*/}
            <IonItem color={"light"}>
                <IonLabel>Rest duration in seconds</IonLabel>
                <IonInput
                    className={"ion-text-right"}
                    type={"number"}
                    inputmode={'numeric'}
                    value={data[index].rest}
                    placeholder={'Optional'}
                    onIonChange={e => {
                        const newArr = [...data]
                        newArr[index].rest = e.detail.value!
                        set(newArr)
                    }}

                >
                </IonInput>


            </IonItem>


        </>
    )
}

export default ExerciseEdit
