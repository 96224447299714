import {Redirect, Route} from 'react-router-dom';
import {IonApp, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Custom Css */
import './theme/custom.css'

import Calendar from "./pages/Calendar/Calendar";
import React from "react";
import Profile from "./pages/Profile/Profile";
import Log from "./pages/Log/Log";
import {faCalendarWeek, faUser, faWhistle} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import {useAuth} from "./context/AuthContext";
import Login from "./pages/login/Login";
import ExerciseList from "./pages/EditExercises/ExerciseList/ExerciseList";
import EditExercise from "./pages/EditExercises/EditExercise/EditExercise";
import RoutinesList from "./pages/EditRoutines/RoutinesList/RoutinesList";
import EditRoutine from "./pages/EditRoutines/EditRoutine/EditRoutine";

import EditRoutineGroup from "./pages/EditRoutines/EditRoutineGroup/EditRoutineGroup";
import {RecoilRoot} from "recoil";

import LogRocket from 'logrocket';
import EditRoutinesList from "./pages/EditRoutines/RoutinesList/EditRoutinesList";


if (process.env.NODE_ENV === 'production') {
    LogRocket.init('2zlm9j/trainlog');
}

const MenuIcon = styled(FontAwesomeIcon)`
  padding-bottom: 5px;
`


const Tabs = (props: any) => {


    return (
        <IonTabs>
            <IonRouterOutlet>
                <Redirect exact path={'/'} to={'/calendar'}/>
                <Route exact={true} path="/calendar" component={Calendar}/>
                <Route exact={true} path={"/profile"} component={Profile}/>
                <Route exact={true} path={"/log"} component={Log}/>
                <Route exact={true} path={'/exerciselist'} component={ExerciseList}/>
                <Route exact={true} path={'/editexercise/:id'} component={EditExercise}/>
                <Route exact={true} path={'/editexercise'} component={EditExercise}/>

                <Route exact={true} path={'/routineslist'} component={RoutinesList}/>
                <Route exact={true} path={'/routineslist/:folder'} component={RoutinesList}/>

                <Route exact={true} path={'/editroutineslist'} component={EditRoutinesList}/>
                <Route exact={true} path={'/editroutineslist/:folder'} component={EditRoutinesList}/>

                <Route exact={true} path={'/editroutine'} component={EditRoutine}/>
                <Route exact={true} path={'/editroutine/:id'} component={EditRoutine}/>
                <Route exact={true} path={'/editroutine/:id/:currentFolder'} component={EditRoutine}/>


                <Route exact={true} path={'/editgroup'} component={EditRoutineGroup}/>
                <Route exact={true} path={'/editgroup/:id'} component={EditRoutineGroup}/>


            </IonRouterOutlet>

            <IonTabBar slot="bottom" id={"tabbar"}>
                <IonTabButton tab="calendar" href={'/calendar'}>
                    <MenuIcon icon={faCalendarWeek} size={"2x"}/>
                    <IonLabel>Calendar</IonLabel>
                </IonTabButton>

                <IonTabButton tab="routines" href={'/routineslist'}>
                    <MenuIcon icon={faWhistle} size={"2x"}/>
                    <IonLabel>Train!</IonLabel>
                </IonTabButton>

                <IonTabButton tab="profile" href={'/profile'}>
                    <MenuIcon icon={faUser} size={"2x"}/>

                    <IonLabel>Profile</IonLabel>
                </IonTabButton>


            </IonTabBar>


        </IonTabs>
    )


}

const App = () => {
    const {currentUser} = useAuth()

    return (
        <RecoilRoot>
            <IonApp>
                <IonReactRouter>
                    <IonRouterOutlet>
                        <Route exact={true} path={"/login"} component={Login}/>


                        <Route path={"/"} component={currentUser ? Tabs : Login}/>


                    </IonRouterOutlet>
                </IonReactRouter>
            </IonApp>
        </RecoilRoot>
    )
}

export default App;
