import React, {useState} from "react"
import {IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonPage, IonTitle, IonToolbar} from "@ionic/react";
import RoutinesListComp from "../../../components/RoutinesListComp";
import {useParams} from "react-router-dom";
import {addOutline} from "ionicons/icons";
import SelectElement from "../../../components/helpers/SelectElement";
import SmallSpace from "../../../components/helpers/SmallSpace";


const RoutinesList = () => {
    let {folder} = useParams<{ folder: string }>();

    // set folder to empty string for root folder
    if (!Boolean(folder)) {
        folder = ''
    }


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    {folder !== '' &&
                    <IonButtons slot={"start"}>
                        <IonBackButton/>
                    </IonButtons>}


                    <IonTitle>Select Routine</IonTitle>


                </IonToolbar>
            </IonHeader>
            <IonContent>
                <SmallSpace space={5}></SmallSpace>
                {folder === '' &&
                <IonItem button>
                    <IonLabel>Improvised Workout</IonLabel>
                </IonItem>
                }


                <RoutinesListComp
                    edit={false}
                    baseLink={'/routineslist'}
                    folder={folder}
                    editMode={false}
                    exerciseLink={''}
                    detailsOption={false}
                    header={false}/>

            </IonContent>
        </IonPage>
    )
}

export default RoutinesList