import 'firebase/compat/auth';
import firebase from 'firebase/compat/app';




// *******************************************************
// Development Config
// *******************************************************
const app = firebase.initializeApp({
    apiKey: "AIzaSyDZ_nqSrH7u3kN8xPyn03wEu2QukS3fHQ0",
    authDomain: "trainlog-dev.firebaseapp.com",
    projectId: "trainlog-dev",
    storageBucket: "trainlog-dev.appspot.com",
    messagingSenderId: "930921003208",
    appId: "1:930921003208:web:d44f2e7d2bba63eb60cdfd",
    measurementId: "G-H8PYTS48RR"
})

const googleProvider = new firebase.auth.GoogleAuthProvider();

export const auth = app.auth()
export const google = googleProvider;
export default app;