import React, {useState} from "react"
import {IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar} from "@ionic/react";
import RoutinesListComp from "../../../components/RoutinesListComp";
import {useParams} from "react-router-dom";
import {addOutline} from "ionicons/icons";


const EditRoutinesList = () => {
    let {folder} = useParams<{ folder: string }>();
    const [editMode, setEditMode] = useState(false)
    // set folder to empty string for root folder
    if (!Boolean(folder)) {
        folder = ''
    }

    // TODO Better back button respecting hierarchy

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>

                    <IonButtons slot={"start"}>
                        <IonBackButton/>
                    </IonButtons>


                    <IonTitle>Edit Routines</IonTitle>
                    <IonButtons slot={"end"}>
                        {editMode
                            ? (<IonButton onClick={() => setEditMode(false)}>Done</IonButton>)
                            : (<IonButton onClick={() => setEditMode(true)}>Edit</IonButton>)
                        }

                    </IonButtons>
                    {!editMode ?
                        <IonButtons slot={"end"}>
                            <IonButton routerLink={'/editroutine/folder/' + folder}><IonIcon icon={addOutline}/></IonButton>
                        </IonButtons>
                        :
                        <IonButtons slot={"end"}>
                            <IonButton routerLink={'/editroutine/folder/' + folder} disabled={true}><IonIcon icon={addOutline}/></IonButton>
                        </IonButtons>
                    }

                </IonToolbar>
            </IonHeader>
            <IonContent>
                <RoutinesListComp edit={true} baseLink={'/editroutineslist'} folder={folder} editMode={editMode} exerciseLink={'/editroutine'}/>

            </IonContent>
        </IonPage>
    )
}

export default EditRoutinesList