import React, {useEffect, useState} from "react"
import {IonBadge, IonItem, IonLabel, IonList, IonNote, IonSearchbar, IonSkeletonText, IonText, useIonViewDidEnter, useIonViewWillEnter} from "@ionic/react";
import styled from "styled-components";
import axios from "axios";
import {myConfig} from "../config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowTurnDownRight, faFolderOpen} from "@fortawesome/pro-duotone-svg-icons";
import {useAuth} from "../context/AuthContext";


const IonSubitem = styled(IonItem)`
  padding-left: 0;
`

// padding right to seperate badge from arrow
const badgePaddingRight = 5;

type ExListProps = {
    linkMaster: boolean;
    url: string;
    clickFunction?: (id: string) => void
    showBadges?: boolean
    displaySubIcon?: boolean
    data: any
    countList: any


}


const ExList = ({linkMaster, url, clickFunction, showBadges = true, displaySubIcon = false, data, countList}: ExListProps) => {


    const [search, setSearch] = useState('');
    // TODO: no any
    //const [data, setData] = useState<any>([])

    // TODO: no any
    //const [countList, setCountList] = useState<any>([])
    //const [loading, setLoading] = useState(true)
    // needed for redraw of the exerciselist


    type ListItemProps = {
        name: string;
        description: string;
        subItem?: boolean;
        always?: boolean;
        id: string;
        isMaster: boolean
        color?: string
    }

    const ListItem = ({name, description, subItem = false, always = false, id, isMaster, color = '#FFFFFF'}: ListItemProps) => {
        // search field
        let found = name.toLowerCase().includes(search.toLowerCase())

        found = always ? true : found;

        // default props
        let props = {
            detail: false,
            button: false
        }

        if (linkMaster && isMaster) {
            props = {
                detail: true,
                button: true
            }
        }
        if (!isMaster) {
            props = {
                detail: true,
                button: true
            }
        }

        // link!!
        const link = url + id

        // default
        let click = {}
        let router = {}
        if (clickFunction) {
            if (!isMaster) {
                click = {
                    onClick: () => clickFunction(id)
                }
            }


        } else {
            router = {
                routerLink: link
            }
        }


        if (found) {
            if (subItem) {
                // is child of master
                return (

                    <IonSubitem detail button {...router} {...click}>
                        {
                            displaySubIcon ?
                                <IonText slot={"start"}><FontAwesomeIcon icon={faArrowTurnDownRight} size={"lg"} style={{marginLeft: "12px"}}/></IonText>
                                :
                                <IonText slot={"start"}><FontAwesomeIcon icon={faFolderOpen} size={"lg"}
                                                                         style={{visibility: "hidden"}}/></IonText>
                        }
                        <IonLabel>
                            <h2>{name}</h2>
                            <IonNote>{description}</IonNote>
                        </IonLabel>
                        {showBadges &&
                        countList.find((itemE: { exerciseid: string, count: number }) => itemE.exerciseid === id)?.count > 0 &&
                        <IonLabel slot={"end"} style={{paddingRight: badgePaddingRight + "px"}}>
                            <IonBadge>{

                                countList.find((itemE: { exerciseid: string, count: number }) => itemE.exerciseid === id).count
                            }</IonBadge>
                        </IonLabel>
                        }
                    </IonSubitem>


                )
            } else {
                return (

                    <IonItem {...props} {...router} {...click}>
                        {isMaster && <IonText slot={"start"}><FontAwesomeIcon icon={faFolderOpen} style={{color: color}} size={"lg"}/></IonText>}
                        <IonLabel>
                            <h2>
                                {name}
                            </h2>
                            <IonNote>
                                {description}
                            </IonNote>
                        </IonLabel>

                        {showBadges &&
                        countList.find((itemE: { exerciseid: string, count: number }) => itemE.exerciseid === id)?.count > 0 &&

                        <IonLabel slot={"end"} style={{paddingRight: badgePaddingRight + "px"}}>
                            <IonBadge>{

                                countList.find((itemE: { exerciseid: string, count: number }) => itemE.exerciseid === id).count
                            }</IonBadge>
                        </IonLabel>
                        }


                    </IonItem>
                )

            }
        } else {
            return null
        }

    }

    type SubListProps = {
        master: string;
    }

    const SubList = ({master}: SubListProps) => {
        let masterId: string
        let masterName: string
        let masterColor: string
        let masterDescription: string

        type masterItemType = {
            name: string
            _id: string
            color: string
            description: string
        }

        const masterItem: masterItemType = data.find((item: any) => item._id === master) || null;

        masterName = masterItem.name
        masterId = masterItem._id
        masterColor = masterItem.color
        masterDescription = masterItem.description

        // templist used to get subItems
        const tempList = []

        data.map((item: { name: string, master: string, description: string }) => (
            (item.master === master)
            &&
            (item.name.toLowerCase().includes(search.toLowerCase()))
            && (
                tempList.push(item.name)
            )
        ))


        return (
            <React.Fragment>
                <ListItem name={masterName} description={masterDescription} always={tempList.length > 0} id={masterId} isMaster={true} color={masterColor}/>
                {data.map((item: { name: string, master: string, description: string, _id: string, color: string }) => (
                    item.master === master && (
                        <ListItem
                            name={item.name}
                            description={item.description}
                            subItem={true}
                            id={item._id}
                            key={item._id}
                            isMaster={false}
                        />
                    )
                ))}
            </React.Fragment>
        )
    }


    return (
        <>
            <IonSearchbar
                value={search}
                onIonChange={e => setSearch(e.detail.value!)}
                inputmode="text"
            >
            </IonSearchbar>
            <IonList>
                {data.map((item: { master: string, name: string, _id: string, isMaster: boolean, description: string, color: string }) =>
                    (
                        item.isMaster
                            ?
                            <SubList master={item._id} key={item._id}/>
                            :
                            !item.master &&
                            <ListItem name={item.name} description={item.description} key={item._id} id={item._id} isMaster={item.isMaster}/>
                    )
                )}
            </IonList>
        </>
    )
}

export default ExList