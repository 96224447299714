import React, {useEffect, useState} from "react"
import {
    IonButton, IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonIcon,
    IonItem,
    IonLabel,
    IonReorder,
    IonReorderGroup,
    IonRouterLink,
    IonText,
    IonToggle, useIonViewWillEnter
} from "@ionic/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDumbbell, faFolder, faPen, faFolderPlus, faTrashCan, faCircleMinus, faCircle} from "@fortawesome/pro-solid-svg-icons";
import {removeCircleOutline} from "ionicons/icons";
import {useAuth} from "../context/AuthContext";
import axios from "axios";
import {myConfig} from "../config";
import styled from "styled-components";

import {ItemReorderEventDetail} from "@ionic/core";

import SmallSpace from "../components/helpers/SmallSpace";
import {useHistory} from "react-router";


const ExerciseTitle = styled.div`
  font-size: 1em;
`

const GroupTitle = styled.div`
  font-size: .8em;
`

const List = styled.ul`
  margin-top: 0;
  list-style: square inside;
  padding-left: 10px;
  margin-bottom: 0;


  li {
    font-size: 0.7em;
    margin-left: 0;
    padding-left: 0;

  }

`

const Light = styled.span`
  color: var(--ion-color-medium);
`

type RoutinesListProps = {
    edit: boolean
    baseLink: string
    folder: string
    editMode: boolean
    exerciseLink: string
    detailsOption?: boolean
    header?: boolean
}


const RoutinesListComp = ({edit, baseLink, folder, editMode, exerciseLink, detailsOption = true, header = true}: RoutinesListProps) => {


    const {currentUser, token} = useAuth();
    const [routinesList, setRoutinesList] = useState([])

    const [exercises, setExercises] = useState([])
    const [loading, setLoading] = useState(true)
    const [detailsView, setDetailsView] = useState(false)
    const [hierarchy, setHierarchy] = useState('/')
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token // for all requests

    useEffect(() => {
        if (folder !== '') {
            axios.get(myConfig.apiURL + '/routine/getHierarchy/' + folder).then((response) => {
                setHierarchy(response.data)

            })
        }
    }, [folder])


    useIonViewWillEnter(() => {
        axios.get(myConfig.apiURL + '/routine/getRoutines/' + currentUser.uid).then((response) => {
            setRoutinesList(response.data.data)
            setExercises(response.data.exercises)
            setLoading(false)
        }).catch((error) => {
            alert(error)
            console.log(error)
        })
    })


    const deleteRoutine = (id: string) => {
        axios.delete(myConfig.apiURL + '/routine/deleteRoutine/' + id).then(() => {
            axios.get(myConfig.apiURL + '/routine/getRoutines/' + currentUser.uid).then((response) => {
                setRoutinesList(response.data.data)
                setExercises(response.data.exercises)
            }).catch((error) => {
                alert(error)
                console.log(error)
            })
        })
    }

    type RoutineProps = {
        item: any
        details: boolean
    }


    const Routine = ({item, details}: RoutineProps) => {
        if (item.isFolder) {
            return (
                <>


                    <IonLabel>
                        <ExerciseTitle>{item.name} {item.description && (<>:: <Light>{item.description}</Light></>)}</ExerciseTitle>
                    </IonLabel>
                </>
            )
        }
        return (
            <>

                <IonLabel>
                    <ExerciseTitle>{item.name} {item.description && (<>:: <Light>{item.description}</Light></>)}</ExerciseTitle>
                    {
                        details && item.data.map((item: any, index: number) => (
                                <div key={item._id}>
                                    <GroupTitle>
                                        {index + 1}. Sets: {
                                        item.setsMin > 0
                                            ? (<>{item.setsMin} - {item.setsMax > 0 ? item.setsMax : <>AMSAP</>}</>)
                                            : <>N/A</>
                                    }&nbsp;
                                        {exercises.filter((i: any) => i.id === item._id).length > 1 && <>(Superset)</>}
                                    </GroupTitle>
                                    <List>
                                        {exercises.filter((i: any) => i.id === item._id).map((ex: any) => (
                                            <li key={ex.name}>{ex.name}</li>
                                        ))}
                                    </List>
                                </div>
                            )
                        )
                    }
                </IonLabel>
            </>
        )
    }

    function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
        axios.patch(myConfig.apiURL + '/routine/reorderRoutines', {user: currentUser.uid, oldpos: event.detail.from, newpos: event.detail.to, folder: folder})
            .then(() => {
                axios.get(myConfig.apiURL + '/routine/getRoutines/' + currentUser.uid).then((response) => {
                    setRoutinesList(response.data.data)
                    setExercises(response.data.exercises)
                    event.detail.complete()
                })
            }).catch((error) => {
            alert(error)
            console.log(error)
        })
    }

    if (loading) {
        return null
    }


    return (
        <>

            {!editMode && header &&
            <>
                <IonCardHeader>
                    <IonCardTitle>
                        Routines
                    </IonCardTitle>
                    <IonCardSubtitle>
                        <div style={{alignItems: "center", display: "flex"}}>
                            {folder !== '' && <div>{hierarchy}</div>}
                            {edit && folder !== '' &&
                            <div>&nbsp;<IonButton fill={'outline'} size={"small"} routerLink={'/editroutine/' + folder}>Edit folder</IonButton></div>}
                        </div>
                    </IonCardSubtitle>
                </IonCardHeader>
                {detailsOption &&
                <IonItem>
                    <IonLabel>Display details: </IonLabel>
                    <IonToggle
                        checked={detailsView}
                        onIonChange={(e) => setDetailsView(e.detail.checked)}
                    > </IonToggle>
                </IonItem>}

            </>}
            {!header && folder !== '' &&
            < h2 style={{paddingLeft: "16px"}}>
                {hierarchy}
            </h2>}

            <SmallSpace space={10}/>
            <IonReorderGroup disabled={!editMode} onIonItemReorder={doReorder}>
                {routinesList.filter((item: any) => item.folder === folder).map((item: { color: string, name: string, _id: string, description: string, data: any, isFolder: string, folder: string }) =>
                    (

                        editMode ? (
                            <IonItem key={item._id}>

                                <IonButton color={"danger"} slot={"start"} fill={"clear"} onClick={() => deleteRoutine(item._id)}>
                                    <FontAwesomeIcon icon={faCircleMinus} style={{fontSize: "20px"}}/>
                                </IonButton>
                                {item.isFolder &&
                                <IonText>
                                    <div style={{width: "30px"}}>
                                        <FontAwesomeIcon icon={faFolder}/>
                                    </div>
                                </IonText>
                                }
                                <Routine item={item} details={false}/>
                                <IonReorder slot={"end"}/>
                            </IonItem>
                        ) : (
                            item.isFolder ? (
                                <IonItem key={item._id} detail={false} routerLink={baseLink + '/' + item._id}>
                                    <IonText>
                                        <div style={{width: "30px"}}>
                                            <FontAwesomeIcon icon={faFolder} color={item.color}/>
                                        </div>
                                    </IonText>
                                    <Routine item={item} details={detailsView}/>
                                </IonItem>


                            ) : (

                                <IonItem key={item._id} button detail={true} routerLink={exerciseLink + '/' + item._id}>
                                    <IonText>
                                        <div style={{width: "30px"}}>
                                            <FontAwesomeIcon icon={faDumbbell}/>
                                        </div>
                                    </IonText>
                                    <Routine item={item} details={detailsView}/>
                                </IonItem>

                            )

                        )
                    )
                )}
            </IonReorderGroup>


        </>
    )
}

export default RoutinesListComp