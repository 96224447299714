import React from "react"
import {IonInput, IonItem, IonLabel, IonNote, IonToggle} from "@ionic/react";
import NoteElement from "./NoteElement";


type SwitchElementProps = {
    name: string;

    value: boolean
    setter: (value: boolean) => void
    hint?: string
    disabled?: boolean
    

}

const SwitchElement = ({name, value, setter, hint = '', disabled = false}: SwitchElementProps) => {

    return (
        <>
            <IonItem lines={'full'} color={'light'}>
                <IonLabel>
                    {name}
                </IonLabel>
                <IonToggle slot={"end"}
                           checked={value}
                           onIonChange={e => setter(e.detail.checked)}
                           disabled={disabled}
                >

                </IonToggle>
            </IonItem>
            {hint !== '' && (
                <NoteElement note={hint}/>
            )}

        </>
    )
}

export default SwitchElement