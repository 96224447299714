import React, {useEffect, useState} from "react"
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from "@ionic/react";
import styled from "styled-components";

import axios from "axios"

import {myConfig} from "../../config";
import {useAuth} from "../../context/AuthContext"

import {addDays, format, getDay, startOfWeek, subDays} from "date-fns"

import Bar from "./Bar"


const Table = styled.table`
  border: 1px;
  border-color: #2dd36f;
  table-layout: fixed;
  width: 100%;
  margin-top: 5px;

  td {
    border: 1px solid var(--border-light);
    border-collapse: collapse;
    height: 8rem;
    vertical-align: top;
    padding: 0;

  }

  td > .day {
    text-align: center;
    background-color: var(--ion-color-light);
    padding: 1px;
    font-size: small;
  }

  td > .today {
    text-align: center;
    color: var(--ion-color-danger);
    background-color: var(--ion-color-light);
    padding: 1px;
    font-size: small;
  }


`


const Calendar = () => {
    const {currentUser} = useAuth();
    //const history = useHistory()
    const {token} = useAuth()
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token // for all requests

    const [intensityData, setIntensityData] = useState([])
    const [intensityDataRaw, setIntensityDataRaw] = useState([])


    let startDate;
    let endDate;

    useEffect(() => {
        axios.get(myConfig.apiURL + '/intensity/days/' + currentUser.uid).then((response) => {

            setIntensityData(response.data?.final)
            setIntensityDataRaw(response.data?.raw)


            axios.get(myConfig.apiURL + '/plan/' + currentUser.uid).then(() => {

            })
        })

    }, [])


    function displayDays() {
        const firstDOW = startOfWeek(new Date(), {weekStartsOn: 1});
        const weekDays = Array.from(Array(7)).map((e, i) =>
            format(addDays(firstDOW, i), "EEEEEE")
        );

        return (
            <>
                {weekDays.map((day, i) => (
                    <th key={i} className="has-text-centered">
                        {day}
                    </th>
                ))}
            </>
        );
    }

    function displayCells() {
        let printDate: Date;
        let dayOfWeek;
        let days;


        dayOfWeek = getDay(new Date()) === 0 ? 6 : getDay(new Date()) - 1;
        startDate = subDays(new Date(), dayOfWeek + 7);
        endDate = addDays(new Date(), 7 - dayOfWeek + 21);
        days = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24);

        let daysInMonth = [];
        printDate = startDate;


        for (let d = 1; d <= days; d++) {
            printDate = addDays(startDate, d - 1);
            //console.log(intensityData)


            daysInMonth.push(
                <td key={d + 10}>
                    <div className={
                        format(printDate, "MM/dd/yyyy") === format(new Date(), "MM/dd/yyyy") ?
                            ("today") : ("day")
                    }>
                        <strong>{format(printDate, "d")}</strong>
                    </div>
                    <Bar dateStr={format(printDate, "yyyy-MM-dd")}
                         data={intensityData}
                         raw={intensityDataRaw}/>


                </td>
            );

        }


        const totalSlots = [...daysInMonth];
        let rows: any[] = []
        let cells: any[] = [];

        totalSlots.forEach((row, i) => {
            if (i % 7 !== 0) {
                cells.push(row); // if index not equal 7 that means not go to next week
            } else {
                rows.push(cells); // when reach next week we contain all td in last week to rows
                cells = []; // empty container
                cells.push(row); // in current loop we still push current row to new container
            }
            if (i === totalSlots.length - 1) {
                // when end loop we add remain date
                rows.push(cells);
            }
        });

        return rows.map((d, i) => {
            return <tr key={i}>{d}</tr>;
        });
    }


    // TODO slot=fixed, but not working? changes dark theme colors.

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Calendar</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>

                <Table>
                    <thead>
                    <tr>
                        {displayDays()}
                    </tr>
                    </thead>
                    <tbody>
                    {displayCells()}
                    </tbody>
                </Table>


            </IonContent>

        </IonPage>

    )
}

export default Calendar