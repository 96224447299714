import React, {useState} from "react"
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonModal,
    IonPage,
    IonReorder,
    IonReorderGroup,
    IonTitle,
    IonToolbar,
    useIonViewWillEnter
} from "@ionic/react";
import {useParams} from "react-router-dom";
import {chevronBack, removeCircleOutline} from "ionicons/icons";
import axios from "axios";
import {myConfig} from "../../../config";
import ExerciseEdit from "./ExerciseEdit";
import SmallSpace from "../../../components/helpers/SmallSpace";
import ExList from "../../../components/ExList";
import {ItemReorderEventDetail} from "@ionic/core";
import {useAuth} from "../../../context/AuthContext";
import useGetExercises from "../../../hooks/useGetExercises";


const padding: Number = 40;
let exerciseToEdit = 0


const EditRoutineGroup = (props: any) => {
    let {id} = useParams<{ id: string }>()
    const {token} = useAuth()
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token // for all requests
    const [groupid, setGroupid] = useState(id)
    const [setsMin, setSetsMin] = useState('')
    const [setsMax, setSetsMax] = useState('')
    const [exercises, setExercises] = useState([])
    const [showExerciseModal, setShowExerciseModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showEditModal, setShowEditModal] = useState(false)
    const [editMode, setEditMode] = useState(false)


    const [data, countList] = useGetExercises()

    useIonViewWillEnter(() => {

        const getExercises = axios.get(myConfig.apiURL + '/routine/getExercises/' + id)
        const getGroup = axios.get(myConfig.apiURL + '/routine/getGroup/' + id)

        axios.all([getExercises, getGroup])
            .then(axios.spread((exerciseResponse, groupResponse) => {
                setExercises(exerciseResponse.data)
                setSetsMin(groupResponse.data[0].setsMin)
                setSetsMax(groupResponse.data[0].setsMax)
                setLoading(false)
            }))


    })


    const handleSets = () => {
        const min = parseInt(setsMin)
        const max = parseInt(setsMax)
        if (setsMax !== '' && setsMin !== '') {
            if (min > max) {
                setSetsMin(setsMax)
            }
        }
        if (setsMin === '0') {
            setSetsMin('')
        }
        if (setsMax === '0') {
            setSetsMax('')
        }


    }

    const clickFunction = (id: string) => {
        //setLoading(true)
        // Add exercise to the list


        axios.post(myConfig.apiURL + '/routine/saveNewExercise', {groupid: groupid, exerciseid: id})
            .then((r) => {
                axios.get(myConfig.apiURL + '/routine/getExercises/' + groupid)
                    .then((response) => {
                        //console.log(response.data)
                        setExercises(response.data)
                        setShowExerciseModal(false)
                        setLoading(false)


                    })
            })
    }

    const clickFunctionEdit = (index: number) => {
        // change the exercise
        // id is the ID in routineexercise
        setShowEditModal(true)
        exerciseToEdit = index


    }

    const clickFunctionFinal = (id: string) => {
        // loading is important, otherwise the view is not updated
        setLoading(true)
        // id of the new exercise

        axios.get(myConfig.apiURL + '/exercise/id/' + id)
            .then((response) => {
                // the response data should no go in the data propery of the exercise index
                const newArr: any = [...exercises]


                newArr[exerciseToEdit].exerciseid = id
                newArr[exerciseToEdit].data = [response.data]

                setExercises(newArr)
                //console.log(newArr)

                setShowEditModal(false)
                setLoading(false)
            })


    }

    const submit = () => {

        // save the array to routineexercises

        axios.patch(myConfig.apiURL + '/routine/updateExercise', exercises).then((response) => {

            axios.post(myConfig.apiURL + '/routine/updateRoutineGroup/' + groupid, {setsMin, setsMax})
                .then((r) => {
                    props.history.goBack()
                })
                .catch((error) => {
                    alert(error)
                    props.history.goBack()
                })

        }).catch((error) => {
            alert(error)
            props.history.goBack()
        })
    }

    const toggleEditMode = () => {
        setEditMode(!editMode)
    }

    function immutableMove(arr: any, from: number, to: number) {
        // found here:
        // https://stackoverflow.com/questions/2440700/reordering-arrays/2440723
        return arr.reduce((prev: any, current: any, idx: number, self: any) => {
            if (from === to) {
                prev.push(current);
            }
            if (idx === from) {
                return prev;
            }
            if (from < to) {
                prev.push(current);
            }
            if (idx === to) {
                prev.push(self[from]);
            }
            if (from > to) {
                prev.push(current);
            }
            return prev;
        }, []);
    }

    function doReorder(event: CustomEvent<ItemReorderEventDetail>) {

        // first reorder the array
        const newArray = immutableMove(exercises, event.detail.from, event.detail.to)

        // then adjust the position value
        newArray.forEach((item: any, index: number) => {
            item.position = index
        })

        // then assign to the state
        setExercises(newArray)

        event.detail.complete()
    }

    const deleteExercise = (index: number) => {

        // get rid of the exercise
        const newArray = exercises
        newArray.splice(index, 1)


        // recompute position
        newArray.forEach((item: any, index: number) => {
            item.position = index
        })

        // then assign to the state
        setExercises(newArray)
        setEditMode(false)

    }


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot={"start"}>
                        {
                            // TODO: Make is more like the <IconBackButton> design
                        }
                        {!editMode &&
                        <IonButton fill={"clear"} onClick={submit}>
                            <IonIcon icon={chevronBack} size={'large'}/>Back
                        </IonButton>}

                    </IonButtons>
                    <IonTitle>Edit Exercise</IonTitle>
                    <IonButtons slot={"end"}>
                        <IonButton onClick={toggleEditMode}>
                            {editMode ? 'Done' : 'Edit'}
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>

                {!editMode &&
                <IonItem color={"light"}>
                    <IonLabel position="fixed" slot={"start"}>Sets Min:</IonLabel>
                    <IonInput
                        inputmode={'numeric'}
                        type={'number'}
                        placeholder={"Optional"}
                        className={"ion-text-right"}
                        value={setsMin}
                        onIonBlur={handleSets}
                        onIonChange={e => setSetsMin(e.detail.value!)}
                    >
                    </IonInput>
                    <IonLabel
                        position="fixed"
                        placeholder={"Optional"}
                        style={{paddingLeft: padding}}>
                        Max:
                    </IonLabel>
                    <IonInput
                        type={'number'}
                        inputmode={'numeric'}
                        placeholder={"Optional"}
                        className={"ion-text-right"}
                        value={setsMax}
                        onIonChange={e => setSetsMax(e.detail.value!)}
                        onIonBlur={handleSets}
                    >
                    </IonInput>
                </IonItem>
                }


                {!loading &&
                editMode ?
                    <IonReorderGroup disabled={false} onIonItemReorder={doReorder}>
                        {exercises.map((exercise: any, index) => (
                            <IonItem key={exercise._id}>
                                <IonButton slot={"start"} fill={"clear"} onClick={(e) => deleteExercise(index)}>
                                    <IonIcon
                                        icon={removeCircleOutline} color={"danger"} style={{fontSize: "24px"}}/>
                                </IonButton>
                                {exercise.data[0].name}
                                <IonReorder slot={"end"}/>
                            </IonItem>
                        ))}
                    </IonReorderGroup>
                    :

                    exercises.map((exercise: any, index) => (
                        <ExerciseEdit
                            editfunction={clickFunctionEdit}
                            key={exercise._id}
                            index={index}

                            set={setExercises}

                            data={exercises}

                        />
                    ))


                }

                {!editMode &&
                <>
                    <SmallSpace space={5}/>
                    <IonButton expand={"block"} onClick={() => setShowExerciseModal(true)}>Add Exercise</IonButton>
                </>}


                {/*Modal*/}
                <IonModal
                    isOpen={showExerciseModal}
                    swipeToClose={true}
                    onDidDismiss={() => setShowExerciseModal(false)}
                >

                    <IonHeader>
                        <IonToolbar>
                            <IonButtons slot={"start"}>
                                <IonButtons slot={"start"}>
                                    <IonButton onClick={() => setShowExerciseModal(false)}>Cancel</IonButton>
                                </IonButtons>
                            </IonButtons>
                            <IonTitle>Select Exercise</IonTitle>

                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <ExList linkMaster={false} url={''} clickFunction={clickFunction} showBadges={false} data={data} countList={countList}/>

                    </IonContent>

                </IonModal>

                <IonModal
                    isOpen={showEditModal}
                    swipeToClose={true}
                    onDidDismiss={() => setShowEditModal(false)}
                >

                    <IonHeader>
                        <IonToolbar>
                            <IonButtons slot={"start"}>
                                <IonButtons slot={"start"}>
                                    <IonButton onClick={() => setShowEditModal(false)}>Cancel</IonButton>
                                </IonButtons>
                            </IonButtons>
                            <IonTitle>Select Exercise</IonTitle>

                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <ExList linkMaster={false} url={''} clickFunction={clickFunctionFinal} showBadges={false} data={data} countList={countList}/>

                    </IonContent>

                </IonModal>


            </IonContent>
        </IonPage>
    )
}

export default EditRoutineGroup