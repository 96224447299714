/* jshint node: true */


/*let myConfig = {
    apiURL: 'http://192.168.178.81:5000',
    //apiURL: 'https://api.traincal.app',
    baseColors: [
        {
            etype: "Chest",
            color: "red"
        },
        {
            etype: "Legs",
            color: "green"
        },
        {
            etype: "Back",
            color: "Cyan"
        },
        {
            etype: "Triceps",
            color: "yellow"
        },
        {
            etype: "Biceps",
            color: "MediumOrchid"
        },
        {
            etype: "Abs",
            color: "#ff8811"
        },
        {
            etype: "Shoulders",
            color: "Aqua"
        }
    ],


    typeArray: [
        {
            type: 'Chest',
        },

        {
            type: 'Legs',
        },
        {
            type: 'Back',
        },
        {
            type: 'Abs',
        },
        {
            type: 'Triceps',
        },
        {
            type: 'Biceps',
        },
        {
            type: 'Shoulders'
        },
    ],

    bodyPartData: [
        {
            name: 'Chest',
        },

        {
            name: 'Legs',
        },
        {
            name: 'Back',
        },
        {
            name: 'Abs',
        },
        {
            name: 'Triceps',
        },
        {
            name: 'Biceps',
        },
        {
            name: 'Shoulders'
        },
    ]


};*/
let myConfig = {};


if (process.env.NODE_ENV !== 'production') {
    myConfig.apiURL = 'http://192.168.178.81:5000';
} else {
    myConfig.apiURL = 'https://api.traincal.app';
}

myConfig.baseColors = [
    {
        etype: "Chest",
        color: "red"
    },
    {
        etype: "Legs",
        color: "green"
    },
    {
        etype: "Back",
        color: "Cyan"
    },
    {
        etype: "Triceps",
        color: "yellow"
    },
    {
        etype: "Biceps",
        color: "MediumOrchid"
    },
    {
        etype: "Abs",
        color: "#ff8811"
    },
    {
        etype: "Shoulders",
        color: "Aqua"
    }
];

myConfig.bodyPartData = [
    {
        name: 'Chest',
    },

    {
        name: 'Legs',
    },
    {
        name: 'Back',
    },
    {
        name: 'Abs',
    },
    {
        name: 'Triceps',
    },
    {
        name: 'Biceps',
    },
    {
        name: 'Shoulders'
    },
];

export {myConfig as myConfig};