import React from "react"
import {IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonPage, IonTitle, IonToolbar} from "@ionic/react";
import ExList from "../../../components/ExList";
import {addOutline} from "ionicons/icons";
import useGetExercises from "../../../hooks/useGetExercises";


const ExerciseList = () => {


    // useHideToolbar()

    const [data, countList] = useGetExercises()


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot={"start"}>
                        <IonBackButton/>
                    </IonButtons>
                    <IonTitle>Exercise List</IonTitle>
                    <IonButtons slot={"end"}>
                        <IonButton routerLink={'/editexercise'}><IonIcon icon={addOutline}/></IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <ExList linkMaster={true} url={'/editexercise/'} showBadges={true} data={data} countList={countList}/>
            </IonContent>
        </IonPage>
    )
}

export default ExerciseList