import React from "react"
import {IonInput, IonItem, IonLabel, IonNote} from "@ionic/react";
import SmallSpace from "./SmallSpace";
import NoteElement from "./NoteElement";


type InputElementProps = {
    name: string;
    placeholder: string;
    value: string
    setter: (value: string) => void
    hint?: string
}

const InputElement = ({name, placeholder, value, setter, hint = ''}: InputElementProps) => {

    return (
        <>
            <IonItem lines={'full'}>
                <IonLabel>
                    {name}
                </IonLabel>

            </IonItem>
            <IonItem color={"light"} lines={'full'}>
                <IonInput placeholder={placeholder} value={value} onIonChange={(e) => setter(e.detail.value!)}></IonInput>
            </IonItem>
            {hint !== '' && (
                <NoteElement note={hint}/>
            )}


        </>
    )
}

export default InputElement