import React from "react"
import {IonInput, IonItem, IonLabel, IonNote, IonToggle} from "@ionic/react";
import NoteElement from "./NoteElement";


type ColorElementProps = {
    name: string;

    value: string
    setter: (value: string) => void
    hint?: string


}

const ColorElement = ({name, value, setter, hint = ''}: ColorElementProps) => {

    return (
        <>
            <IonItem lines={'full'} color={'light'}>
                <IonLabel>
                    {name}
                </IonLabel>
                <input type="color" value={value} onChange={(e) => setter(e.target.value)}/>
            </IonItem>
            {hint !== '' && (
                <NoteElement note={hint}/>
            )}

        </>
    )
}

export default ColorElement