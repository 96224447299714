import React from "react"
import {IonInput, IonItem, IonLabel, IonNote, IonSelect, IonSelectOption, IonToggle} from "@ionic/react";
import NoteElement from "./NoteElement";


type SelectElementProps = {
    name: string;
    values: any;
    value: string;
    setter: (value: string) => void;
    hint?: string;
    none: boolean

}

const SelectElement = ({name, value, setter, values, hint = '', none = false}: SelectElementProps) => {

    return (
        <>
            <IonItem lines={'full'} color={'light'}>
                <IonLabel>
                    {name}
                </IonLabel>
                <IonSelect
                    value={value}
                    placeholder="Select One"
                    onIonChange={e => setter(e.detail.value)}
                    interface="popover"
                >
                    {none && <IonSelectOption value={''}>None</IonSelectOption>}


                    {values.map((item: any) => (
                        <IonSelectOption key={item.name} value={item._id}>{item.name}</IonSelectOption>
                    ))}


                </IonSelect>
            </IonItem>
            {hint !== '' && (
                <NoteElement note={hint}/>
            )}

        </>
    )
}

export default SelectElement