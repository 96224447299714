import React, {useRef, useState} from "react"
import {
    IonButton,
    IonCard, IonCardContent,
    IonCardHeader,
    IonCardSubtitle, IonCardTitle, IonChip,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonNote,
    IonPage, IonRouterLink, IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleCheck, faCircleExclamation} from "@fortawesome/pro-solid-svg-icons";

import {

    RouteComponentProps
} from "react-router-dom";

import {useAuth} from "../../context/AuthContext"
import SmallSpace from "../../components/helpers/SmallSpace";

const Login = (props: RouteComponentProps) => {
    const [error, setError] = useState('')

    const {login, loginWithGoogle} = useAuth()
    const {signup} = useAuth()

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')
    const [signupOK, setSignupOK] = useState(false)
    const [passworStatus, setPassworStatus] = useState(<></>)
    const [emailStatus, setEmailStatus] = useState(<></>)

    const loginHandler = async () => {
        try {
            setError('');

            await login(email, password);

        } catch (error) {
            setError('Wrong password/Email')


            return;
        }


    }


    const signupHandler = async () => {


        if (password !== passwordConfirmation) {
            return setError('Passwords do not match')
        }
        try {
            setError('');

            await signup(email, password);
        } catch (error) {
            setError('Failed to create account')

            return
        }
        
        props.history.push("/")
    }


    const passwordCheck = (password: string, confirmation: string, email: string) => {
        let emailOK = false
        let passwordOK = false
        if (password === confirmation) {
            if (password.length < 6) {

                setPassworStatus(<><FontAwesomeIcon icon={faCircleExclamation} color={"red"}/> Password too short (minimum 6 characters)</>)


            } else {

                setPassworStatus(<><FontAwesomeIcon icon={faCircleCheck} color={"green"}/> Passwords match</>)
                passwordOK = true
            }

        } else {

            setPassworStatus(<><FontAwesomeIcon icon={faCircleExclamation} color={"red"}/> Passwords DO NOT MATCH</>)

        }

        if (!validateEmail(email)) {
            setEmailStatus(<><FontAwesomeIcon icon={faCircleExclamation} color={"red"}/> Invalid Email</>)


        } else {
            setEmailStatus(<></>)
            emailOK = true
        }

        if (emailOK && passwordOK) {
            setSignupOK(true)
        } else {
            setSignupOK(false)
        }

    }

    const handlePassword = (e: any) => {
        setPassword(e.detail.value)
        passwordCheck(e.detail.value, passwordConfirmation, email)
    }

    const handlePasswordConfirmation = (e: any) => {
        setPasswordConfirmation(e.detail.value)
        passwordCheck(password, e.detail.value, email)
    }

    function validateEmail(email: string) {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    const handleEmail = (e: any) => {
        setEmail(e.detail.value)
        passwordCheck(password, passwordConfirmation, e.detail.value)

    }


    if (props.location.pathname === "/signup") {
        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>TrainCal</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonCard>
                        <IonCardHeader>
                            <IonCardTitle className={"ion-text-center"}>Signup</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            {error && (
                                <div className={"ion-text-center"}>
                                    <IonChip color={"danger"}>
                                        <h1>{error}</h1>
                                    </IonChip>
                                </div>
                            )}

                            <IonItem>
                                <IonLabel position={"floating"}>Email</IonLabel>
                                <IonInput type={"email"} value={email} onIonChange={handleEmail}></IonInput>
                            </IonItem>
                            {emailStatus}
                            <IonItem>
                                <IonLabel position={"floating"}>Password</IonLabel>
                                <IonInput type={"password"} value={password} onIonChange={handlePassword}></IonInput>
                            </IonItem>
                            <IonItem>
                                <IonLabel position={"floating"}>Password Confirmation</IonLabel>
                                <IonInput type={"password"} value={passwordConfirmation} onIonChange={handlePasswordConfirmation}></IonInput>
                            </IonItem>
                            {passworStatus}


                            <div className={"ion-text-center"}>
                                <IonButton onClick={signupHandler} disabled={!signupOK} type={"submit"}>Signup</IonButton>
                            </div>

                            <SmallSpace space={10}/>
                            <div className={"ion-text-center"}>
                                Already have an account? <IonRouterLink routerLink={'/login'}>Login</IonRouterLink>
                            </div>


                        </IonCardContent>

                    </IonCard>

                </IonContent>
            </IonPage>
        )
    } else {


        return (
            <IonPage>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>TrainCal</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonCard>
                        <IonCardHeader>
                            <IonCardTitle className={"ion-text-center"}>Login</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent>
                            {error && (
                                <div className={"ion-text-center"}>
                                    <IonChip color={"danger"}>
                                        <h1>{error}</h1>
                                    </IonChip>
                                </div>
                            )}

                            <IonItem>
                                <IonLabel position={"floating"}>Email</IonLabel>
                                <IonInput type={"email"} value={email} onIonChange={e => setEmail(e.detail.value!)}></IonInput>
                            </IonItem>
                            <IonItem>
                                <IonLabel position={"floating"}>Password</IonLabel>
                                <IonInput type={"password"} value={password} onIonChange={e => setPassword(e.detail.value!)}></IonInput>
                            </IonItem>
                            <div className={"ion-text-center"}>
                                <IonButton onClick={loginHandler}>Login</IonButton>
                            </div>

                            <SmallSpace space={10}/>
                            <div className={"ion-text-center"}>
                                Need an account? <IonRouterLink routerLink={'/signup'}>Sign up</IonRouterLink>
                            </div>


                        </IonCardContent>

                    </IonCard>

                </IonContent>
            </IonPage>
        )
    }
}

export default Login