import React, {useState} from "react"
import {
    IonButton,
    IonButtons,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonModal,
    IonPage,
    IonReorder,
    IonReorderGroup,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToggle,
    IonToolbar,
    useIonViewWillEnter
} from "@ionic/react";
import {ItemReorderEventDetail} from '@ionic/core';
import InputElement from "../../../components/helpers/InputElement";
import {useAuth} from "../../../context/AuthContext";
import SmallSpace from "../../../components/helpers/SmallSpace";
import ExList from "../../../components/ExList";
import {useParams} from "react-router-dom";
import axios from "axios";
import {myConfig} from "../../../config";
import {chevronBack, removeCircleOutline} from "ionicons/icons";
import {useHistory} from "react-router";
import useGetExercises from "../../../hooks/useGetExercises";


const EditRoutine = () => {

    const history = useHistory()
    const {currentUser, token} = useAuth();
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token // for all requests
    let {id} = useParams<{ id: string }>();
    if (id === 'folder') {
        id = undefined!
    }
    const {currentFolder} = useParams<{ currentFolder: string }>();

    const [groupsList, setGroupsList] = useState<any>([])
    const [exerciseList, setExerciseList] = useState([])
    const [routineID, setRoutineID] = useState(id)
    const [name, setName] = useState('Unnamed Workout')
    const [description, setDescription] = useState('')
    const [showExerciseModal, setShowExerciseModal] = useState(false)
    const [disableEdit, setDisableEdit] = useState(true)
    const [itemProps, setItemProps] = useState({detail: true, button: true})
    const [editButtonText, setEditButtonText] = useState('Edit')
    const [loading, setLoading] = useState(true)
    const [isFolder, setIsFolder] = useState(false)
    const [folder, setFolder] = useState('')
    const [folders, setFolders] = useState([])
    const [oldFolder, setOldFolder] = useState('')
    const [disabled, setDisabled] = useState(false)
    const [color, setColor] = useState('')


    const [data, countList] = useGetExercises()

    useIonViewWillEnter(() => {


        // if no id is undefined, create a new one
        if (!routineID) {
            axios.get(myConfig.apiURL + '/routine/getFolders/' + currentUser.uid).then((response) => {
                setFolders(response.data)
                axios.post(myConfig.apiURL + '/routine/saveRoutine/', {user: currentUser.uid, name: 'Unnamed Workout', description: '', folder: ''})
                    .then((response) => {
                        setRoutineID(response.data._id)
                        setOldFolder('')
                        setFolder(currentFolder)
                        setLoading(false)
                    })
            })

        } else {

            const getFolders = axios.get(myConfig.apiURL + '/routine/getFolders/' + currentUser.uid)
            const getGroups = axios.get(myConfig.apiURL + '/routine/getGroups/' + routineID)
            const getRoutine = axios.get(myConfig.apiURL + '/routine/getRoutine/' + routineID)

            axios.all([getFolders, getRoutine, getGroups])
                .then(axios.spread(
                    (
                        foldersResponse,
                        routineResponse,
                        groupsResponse
                    ) => {
                        setFolders(foldersResponse.data)

                        // list with all groups
                        setGroupsList(groupsResponse.data.data)

                        // list with all exercises (to get name, ....)
                        setExerciseList(groupsResponse.data.exercises)

                        setName(routineResponse.data.name)
                        setDescription(routineResponse.data.description)
                        setIsFolder(routineResponse.data.isFolder)
                        setFolder(routineResponse.data.folder)
                        setOldFolder(routineResponse.data.folder)
                        setDisabled(true)
                        setColor(routineResponse.data.color)
                        setLoading(false)

                    }))
                .catch((error) => {
                    alert(error)
                })


        }
    })


    const clickFunction = (id: string) => {
        setLoading(true)
        // Add exercise to the list


        axios.post(myConfig.apiURL + '/routine/saveNewGroup', {
            routineid: routineID,
            exerciseid: id,
        })
            .then(() => {
                axios.get(myConfig.apiURL + '/routine/getGroups/' + routineID)
                    .then((response) => {

                        setGroupsList(response.data.data)
                        setExerciseList(response.data.exercises)

                        setShowExerciseModal(false)
                        setLoading(false)

                    })
            })
    }

    const submit = () => {

        axios.post(myConfig.apiURL + '/routine/saveRoutine',
            {
                id: routineID,
                name: name,
                description: description,
                user: currentUser.uid,
                isFolder: isFolder,
                folder: folder,
                oldFolder: oldFolder,
                color: color
            }).then(() => {
            history.goBack()
        })


    }

    function doReorder(event: CustomEvent<ItemReorderEventDetail>) {
        // The `from` and `to` properties contain the index of the item
        // when the drag started and ended, respectively
        //console.log('Dragged from index', event.detail.from, 'to', event.detail.to);

        axios.patch(myConfig.apiURL + '/routine/reorderExercises', {routineid: routineID, oldpos: event.detail.from, newpos: event.detail.to})
            .then(() => {
                axios.get(myConfig.apiURL + '/routine/getGroups/' + id)
                    .then((response) => {

                            // list with all groups
                            setGroupsList(response.data.data)

                            // list with all exercises (to get name, ....)
                            setExerciseList(response.data.exercises)
                            event.detail.complete()


                        }
                    )


            })

    }

    const enableEditMode = () => {
        if (groupsList.length > 0) {
            if (disableEdit) {
                setItemProps({detail: false, button: false})
                setEditButtonText('Done')
            } else {
                setItemProps({detail: true, button: true})
                setEditButtonText('Edit')
            }
            setDisableEdit(!disableEdit)
        }

    }
    type ExerciseProps = {
        data: any
        index: number
        groupindex: number
    }


    const Exercise = ({data}: ExerciseProps) => {
        if (loading) {
            return <></>
        }


        // get the name of the exercise from exerciseList we know the name property must exist
        // TODO no any... ;)

        const exe: any = exerciseList.find((item: any) => item._id.toString() === data.exerciseid)


        if (exe) {
            return (

                <>


                    <IonRow>
                        <IonCol size={"7"}>
                            {exe.name}</IonCol>
                        <IonCol>

                            {exe.isTimed ? (<>Secs: </>) : (<></>)}
                            {data.repsMax == null
                                ?
                                data.repsMin ?
                                    (<>{data.repsMin}+</>)
                                    :
                                    (<>N/A</>)
                                :
                                (<>{data.repsMin} - {data.repsMax}</>)}

                        </IonCol>
                        {/*<IonCol>

                        {data.intensity && (<>Int:&nbsp;{data.intensity} %</>)}
                        {data.rpe && (<>,&nbsp;RPE:&nbsp;{data.rpe}</>)}

                    </IonCol>*/}
                        <IonCol>

                            {(data.rest === null || data.rest === '') ? 'N/A' : data.rest}

                        </IonCol>
                    </IonRow>
                </>


            )
        } else {
            return (
                <></>
            )
        }
    }

    const deleteExercise = (id: string) => {
        // is is groupid


        axios.patch(myConfig.apiURL + '/routine/deleteGroup', {id: id, routineid: routineID})
            .then(() => {


                axios.get(myConfig.apiURL + '/routine/getGroups/' + routineID)
                    .then((response) => {
                        setGroupsList(response.data.data)
                        setExerciseList(response.data.exercises)
                        if (response.data.data.length === 0) {
                            setItemProps({detail: true, button: true})
                            setEditButtonText('Edit')

                            setDisableEdit(!disableEdit)
                        }


                    })
            })
    }

    const getFolders = () => {


        return folders


    }


    return (

        <IonPage>

            <IonHeader>
                <IonToolbar>
                    <IonButtons slot={"start"}>
                        {
                            // TODO: Make is more like the <IconBackButton> design
                        }

                        <IonButton onClick={submit} fill={"clear"}>
                            <IonIcon icon={chevronBack} size={'large'}/>Back
                        </IonButton>

                    </IonButtons>
                    <IonTitle>Edit Routine</IonTitle>
                    {!isFolder &&
                    <IonButtons slot={"end"}>
                        <IonButton onClick={enableEditMode}>{editButtonText}</IonButton>
                    </IonButtons>}

                </IonToolbar>
            </IonHeader>
            <IonContent>

                <InputElement
                    name={'Name'}
                    placeholder={'Name of the Routine'}
                    value={name}
                    setter={setName}/>
                <SmallSpace space={5}/>
                <InputElement
                    name={'Description'}
                    placeholder={'Routine Description'}
                    value={description}
                    setter={setDescription}/>
                <IonItem>
                    <IonLabel>
                        Is Folder
                    </IonLabel>
                    <IonToggle checked={isFolder} onIonChange={(e) => setIsFolder(e.detail.checked)} disabled={disabled}>
                    </IonToggle>
                </IonItem>
                <IonItem>
                    <IonLabel>
                        Select Folder
                    </IonLabel>
                    <IonSelect

                        value={folder}

                        placeholder="Select Folder"
                        onIonChange={e => setFolder(e.detail.value)}
                        interface="action-sheet"
                    >
                        {<IonSelectOption value={''}>None</IonSelectOption>}


                        {getFolders().map((item: any) => (
                            <IonSelectOption key={item.name} value={item._id}>{item.name}</IonSelectOption>
                        ))}


                    </IonSelect>
                </IonItem>

                {isFolder && (
                    <IonItem>
                        <IonLabel>
                            Color
                        </IonLabel>
                        <input type={"color"} value={color} onChange={(e) => setColor(e.target.value)}/>
                    </IonItem>
                )}

                {!isFolder &&
                (
                    <>
                        <SmallSpace space={10}/>


                        <IonButton expand={"block"} onClick={() => setShowExerciseModal(true)}>Add Exercise</IonButton>


                        {!loading &&
                        <IonReorderGroup disabled={disableEdit} onIonItemReorder={doReorder}>
                            {
                                // Either show delete and reorder icons or let the user click the item to edit the exercise
                                disableEdit ?
                                    groupsList.map((item: any, groupindex: number) => (
                                            <IonItem key={item._id} {...itemProps} routerLink={'/editgroup/' + item._id} style={{"--padding-start": "5px"}}>
                                                <IonGrid>
                                                    <IonRow>
                                                        <IonCol size={"7"}>
                                                            {item.setsMax == null
                                                                ?
                                                                item.setsMin ?
                                                                    (<><strong>Sets:</strong> {item.setsMin}+</>)
                                                                    :
                                                                    (<strong>N/A</strong>)
                                                                :
                                                                (<>Sets: {item.setsMin} - {item.setsMax}</>)}
                                                        </IonCol>
                                                        <IonCol>
                                                            <div style={{fontVariant: "small-caps"}}>Reps</div>
                                                        </IonCol>
                                                        <IonCol>
                                                            <div style={{fontVariant: "small-caps"}}>Rest</div>
                                                        </IonCol>

                                                    </IonRow>
                                                    {item.data.map((ex: any, index: number) => (
                                                        <Exercise data={ex} index={index} groupindex={groupindex} key={ex._id}/>
                                                    ))}
                                                </IonGrid>
                                                <IonReorder slot={"end"}/>
                                            </IonItem>
                                        )
                                    )
                                    :
                                    groupsList.map((item: any, groupindex: number) => (
                                            <IonItem key={item._id}>
                                                <IonButton slot={"start"} fill={"clear"} onClick={() => deleteExercise(item._id)}>
                                                    <IonIcon
                                                        icon={removeCircleOutline} color={"danger"} style={{fontSize: "24px"}}/>
                                                </IonButton>
                                                <IonGrid>
                                                    {item.data.map((ex: any, index: number) => (
                                                        <Exercise data={ex} index={index} groupindex={groupindex} key={ex._id}/>
                                                    ))}
                                                </IonGrid>
                                                <IonReorder slot={"end"}/>
                                            </IonItem>
                                        )
                                    )
                            }
                        </IonReorderGroup>}

                    </>
                )}


                <IonModal
                    isOpen={showExerciseModal}
                    swipeToClose={true}
                    onDidDismiss={() => setShowExerciseModal(false)}
                >

                    <IonHeader>
                        <IonToolbar>
                            <IonButtons slot={"start"}>
                                <IonButtons slot={"start"}>
                                    <IonButton onClick={() => setShowExerciseModal(false)}>Cancel</IonButton>
                                </IonButtons>
                            </IonButtons>
                            <IonTitle>Select Exercise</IonTitle>

                        </IonToolbar>
                    </IonHeader>
                    <IonContent>
                        <ExList linkMaster={false} url={''} clickFunction={clickFunction} showBadges={false} data={data} countList={countList}/>

                    </IonContent>

                </IonModal>


            </IonContent>

        </IonPage>

    )
}

export default EditRoutine