import React, {useEffect, useState} from "react"
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonPage,
    IonTitle,
    IonToolbar,
    useIonAlert,
    useIonViewWillEnter
} from "@ionic/react";
import {chevronBack} from "ionicons/icons";
import {useParams} from "react-router-dom";
import SmallSpace from "../../../components/helpers/SmallSpace";
import InputElement from "../../../components/helpers/InputElement";
import SwitchElement from "../../../components/helpers/SwitchElement";
import axios from "axios";
import {myConfig} from "../../../config";
import SelectElement from "../../../components/helpers/SelectElement";
import ColorElement from "../../../components/helpers/ColorElement";
import {useAuth} from "../../../context/AuthContext";


const EditExercise = (props: any) => {


    const {currentUser} = useAuth();
    const [name, setName] = useState('')
    const [description, setDescription] = useState('')
    const [isMaster, setIsMaster] = useState(false)
    const [master, setMaster] = useState<string>('')
    const [isTimed, setIsTimed] = useState<boolean>(false)
    const [isUnilateral, setIsUnilateral] = useState<boolean>(false)
    const [unit, setUnit] = useState<string>('kg')
    const [bodyPart, setBodyPart] = useState<string>('')
    const [present] = useIonAlert()
    const [color, setColor] = useState<string>('#FFFFFF')
    const [masterData, setMasterData] = useState([])
    const [disabled, setDisabled] = useState<boolean>(false)
    const [link, setLink] = useState<string>('')


    const [usage, setUsage] = useState(0)
    const [loading, setLoading] = useState(true)


    const params: { id: string } = useParams()
    let id = params.id
    if (id === undefined) {
        id = ''
    }


    const {token} = useAuth()
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token // for all requests


    const unitData = [
        {
            name: 'kg'
        },
        {
            name: 'lbs'
        },
        {
            name: 'custom'
        },
    ]

    const bodyPartData = myConfig.bodyPartData


    useIonViewWillEnter(() => {

        const getMasters = axios.get(myConfig.apiURL + '/exercise/masters')
        const getExercise = axios.get(myConfig.apiURL + '/exercise/id/' + id)
        const getUsage = axios.get(myConfig.apiURL + '/exercise/getUsage/' + id)

        if (id !== '') {
            axios.all([getMasters, getExercise, getUsage]).then(axios.spread((masterResponse, exerciseResponse, usageResponse) => {
                setMasterData(masterResponse.data)
                setName(exerciseResponse.data.name)
                setDescription(exerciseResponse.data.description)
                setIsMaster(exerciseResponse.data.isMaster)
                setMaster(exerciseResponse.data.master)
                setIsTimed(exerciseResponse.data.isTimed)
                setIsUnilateral(exerciseResponse.data.isUnilateral)
                setUnit(exerciseResponse.data.unit)
                setBodyPart(exerciseResponse.data.bodyPart)
                setColor(exerciseResponse.data.color)
                setLink(exerciseResponse.data.link)
                setDisabled(true)
                setUsage(usageResponse.data.count)
                setLoading(false)
            })).catch((error) => {
                alert(error)
            })
        } else {
            axios.get(myConfig.apiURL + '/exercise/masters').then((response) => {
                setMasterData(response.data)
                setLoading(false)
            }).catch((error) => {
                alert(error)
            })
        }
    })

    const submit = () => {


        axios.post(myConfig.apiURL + '/exercise/user', {
            user: currentUser.uid,
            name,
            description,
            isTimed,
            isUnilateral,
            unit,
            bodyPart,
            color,
            isMaster,
            master,
            id,
            link
        }).then(() => {
            /*setName('')
            setDescription('')
            setIsTimed(false)
            setIsUnilateral(false)
            setUnit('kg')
            setBodyPart('')
            setColor('')
            setIsMaster(false)
            setMaster('')
            setLink('')
            setDoUpdate(true)
            id = ''*/

            props.history.goBack()


        })

    }

    const deleteExercise = () => {
        axios.delete(myConfig.apiURL + '/exercise/id/' + id)
            .then((response) => {
                props.history.goBack()

            })
    }


    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>

                    <IonButtons slot={"start"}>
                        {
                            // TODO: Make is more like the <IconBackButton> design
                        }

                        <IonButton onClick={submit} fill={"clear"}>
                            <IonIcon icon={chevronBack} size={'large'}/>Back
                        </IonButton>

                    </IonButtons>
                    {/*<IonButtons slot={"start"}>
                        <IonBackButton defaultHref={'/exerciselist'} text={'Cancel'}></IonBackButton>
                    </IonButtons>*/}
                    <IonTitle>Edit Exercise</IonTitle>

                </IonToolbar>
            </IonHeader>
            <IonContent>
                {!loading && (
                    <>
                        <InputElement name={'Name'} placeholder={'Exercise name'} value={name} setter={setName}/>
                        <SmallSpace space={5}/>
                        <InputElement name={'Description'} placeholder={'Description'} value={description} setter={setDescription}/>
                        <SmallSpace space={5}/>
                        <SwitchElement name={'Is Master Exercise'} value={isMaster} setter={setIsMaster} hint={'Sets a category'} disabled={disabled}/>
                        {!isMaster && (
                            <>
                                <SmallSpace space={5}/>
                                <SelectElement name={'Master Exercise'} values={masterData} value={master} setter={setMaster}
                                               hint={'Select Master Exercise, the current exercise becomes a variant'} none={true}/>
                                <SmallSpace space={5}/>
                                <SwitchElement name={"Is Timed"} value={isTimed} setter={setIsTimed} hint={"Record time instead of reps"}/>
                                <SmallSpace space={5}/>
                                <SwitchElement name={"Is Unilateral"} value={isUnilateral} setter={setIsUnilateral}
                                               hint={"Record exercise for left and right"}/>
                                <SmallSpace space={5}/>
                                <SelectElement name={'Unit'} values={unitData} value={unit} setter={setUnit}
                                               hint={'Record unit'} none={false}/>
                                <SmallSpace space={5}/>
                                <SelectElement name={"Body Part"} values={bodyPartData} value={bodyPart} setter={setBodyPart} none={true}/>
                                <SmallSpace space={5}/>
                                <InputElement name={'Link'} placeholder={'http://'} value={link} setter={setLink}
                                              hint={"Link to a reference, e.g. YouTube video"}/>
                                <SmallSpace space={5}/>


                            </>
                        )}
                        {isMaster && (
                            <ColorElement name={"Select Color"} value={color} setter={setColor}/>
                        )}
                        <SmallSpace space={5}/>


                        {/*Check for usage*/}
                        {usage > 0 ?
                            isMaster ?
                                <IonItem color={"warning"}>
                                    <IonLabel className={"ion-text-center"}>
                                        {usage} {usage === 1 ? <>child</> : <>children</>} found. Cannot be deleted.
                                    </IonLabel>
                                </IonItem>
                                :
                                <IonItem color={"warning"}>
                                    <IonLabel className={"ion-text-center"}>
                                        Used {usage} times. Cannot be deleted.
                                    </IonLabel>
                                </IonItem>
                            :
                            <></>
                        }
                        <IonButton expand={"block"} color={"danger"} disabled={usage > 0 || id === ''} onClick={
                            () =>
                                present({

                                    header: 'Delete?',
                                    message: 'This can\'t be undone!',
                                    backdropDismiss: false,

                                    buttons: [
                                        {text: 'Ok', handler: deleteExercise},
                                        'Cancel',

                                    ],

                                })
                        }
                        >
                            Delete
                        </IonButton>
                        <SmallSpace space={20}/>
                    </>
                )}


            </IonContent>
        </IonPage>
    )
}

export default EditExercise