import React, {Fragment} from "react";
import {v4 as uuidv4} from 'uuid';
import {myConfig} from "../../config"
import styled from "styled-components";

let tinycolor = require('tinycolor2');

const baseColors: any = myConfig.baseColors;

const BarDisplay = styled.div`
  background-color: ${props => props.color};
  display: flex;
  justify-content: space-between;
  font-family: /*iOS*/ "AvenirNextCondensed-Bold", "Futura-CondensedExtraBold", /*MacOSX*/ HelveticaNeue-CondensedBold, /*Ubuntu*/ "Ubuntu Condensed", "Liberation Sans Narrow", /*Windows*/ "Franklin Gothic Demi Cond", "Arial Narrow", /*Android*/ sans-serif-condensed, /*Fallback*/ Arial, "Trebuchet MS", "Lucida Grande", Tahoma, Verdana, sans-serif;
  font-stretch: condensed;
  font-weight: 500;
  font-size: 0.6rem;
  padding-right: 2px;
  padding-left: 1px;
  color: #0d0d0d;


`


type BarDivProps = {
    intensity: number
    type: string
    newStr: string
    key: string
}

const BarDiv = ({intensity, type, newStr}: BarDivProps) => {

    // TODO: Any?????? Number vs number
    function scaleBetween(unscaledNum: number, minAllowed: number, maxAllowed: number, min: number, max: number) {
        return (maxAllowed - minAllowed) * (unscaledNum - min) / (max - min) + minAllowed
    }

    if (intensity <= 0) return <></>;
    let baseColor = baseColors.find((item: any) => item.etype === type).color;
    // TODO better management of values over 2
    let reduction = scaleBetween(intensity > 2 ? 2 : intensity, 0, 50, 2, 0);
    //console.log(reduction);
    let newColor = tinycolor(baseColor).lighten(reduction).toString();

    let text;
    // TODO: Display intensity optional
    text = <>
        <div>{type}</div>
        <div>{intensity}{newStr}</div>
    </>;

    return <BarDisplay color={newColor}>{text}</BarDisplay>
}

type BarProps = {
    data: any,
    dateStr: string,
    raw: any
}

const Bar = ({data, dateStr, raw}: BarProps) => {
    let out: any[] = []

    data.forEach((element: { date: String, intensity: Number, type: String }) => {
        if (element?.date === dateStr) {
            out.push({intensity: element.intensity, type: element.type});
        }
    })

    out.forEach((item) => {
        let found = raw.findIndex((item2: any) => item2.date === dateStr && item2.type === item.type);
        if (found >= 0) {
            item.new = '˄';
        }
    })


    return (
        <Fragment>
            {out.map((item) => <BarDiv key={uuidv4()} intensity={item.intensity} type={item.type} newStr={item.new}/>)}
        </Fragment>
    );
};

export default Bar;
