import React, {useRef} from "react"
import {
    IonAvatar,
    IonButton,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonPage,
    IonTitle,
    IonToggle,
    IonToolbar
} from "@ionic/react";
import {useAuth} from "../../context/AuthContext"
import SmallSpace from "../../components/helpers/SmallSpace";
import metadata from "../../metadata.json"


const Profile = () => {
    const {logout, currentUser} = useAuth();

    const darkModeRef = useRef<HTMLIonToggleElement>(null)

    const logoutHandler = async () => {
        try {
            await logout()
        } catch (e) {

        }
    }

    const changeDarkMode = () => {
        if (darkModeRef.current?.checked) {
            document.body.classList.add("dark");
        } else {
            document.body.classList.remove("dark");
        }


    }


    return (
        <IonPage>

            <IonHeader>
                <IonToolbar>
                    <IonTitle>Profile</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonCard>
                    <IonItem>
                        <IonAvatar slot={"start"}>
                            <img src="/assets/avatar.svg" alt={"avatar"}/>
                        </IonAvatar>
                        <IonLabel>
                            {currentUser.email}
                        </IonLabel>
                        <IonLabel slot={"end"}>
                            <IonButton size={"default"} onClick={logoutHandler}>Logout</IonButton>
                        </IonLabel>
                    </IonItem>
                </IonCard>

                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>
                            Edit
                        </IonCardTitle>
                    </IonCardHeader>

                    <IonCardContent>
                        <IonItem detail button routerLink={'/exerciselist'}>
                            <IonLabel>Edit Exercises</IonLabel>
                        </IonItem>
                        <IonItem detail button routerLink={'/editroutineslist'}>
                            <IonLabel>Edit Routines</IonLabel>
                        </IonItem>

                    </IonCardContent>
                </IonCard>
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>
                            Settings
                        </IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonItem>
                            <IonLabel>Dark mode</IonLabel>
                            <IonToggle ref={darkModeRef} onClick={changeDarkMode}> </IonToggle>
                        </IonItem>
                    </IonCardContent>
                </IonCard>
                <IonItem>
                    <IonLabel>
                        {`Version ${metadata.buildMajor}.${metadata.buildMinor}.${metadata.buildRevision} ${metadata.buildTag}`}
                    </IonLabel>
                </IonItem>

            </IonContent>

        </IonPage>
    )
}

export default Profile