import React from "react"
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from "@ionic/react";

const Log: React.FC = () => {
    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Ionic Blank</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className="ion-padding">
                Log
            </IonContent>
        </IonPage>
    )
}

export default Log