import React from "react"
import {IonContent, IonHeader, IonPage, IonTitle, IonToolbar} from "@ionic/react";

type SmallSpaceProps = {
    space: number
}

const SmallSpace = ({space = 1}) => {
    return (
        <div style={{padding: space + "px"}}></div>
    )
}

export default SmallSpace