import React, {useState} from "react"
import axios from "axios";
import {myConfig} from "../config";
import {useIonViewWillEnter} from "@ionic/react";
import {useAuth} from "../context/AuthContext";

const useGetExercises = () => {
    const [data, setData] = useState([])
    const [countList, setCountList] = useState([])
    
    const {currentUser, token} = useAuth()


    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token // for all requests

    useIonViewWillEnter(() => {
        axios.get(myConfig.apiURL + '/exercise/list/' + currentUser.uid)
            .then((response) => {

                setData(response.data.list)
                setCountList(response.data.countList)


            }).catch((error) => {
                alert(error)
                console.log(error)
            }
        )

    })

    return [data, countList] as const


}

export default useGetExercises